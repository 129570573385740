@import "../../../../styles/variables";

#employee-main-page {
  max-width: 100% !important;
  display: grid;
  grid-template-columns: 250px auto;
  margin-bottom: 2rem;
  column-gap: 2rem;
  .dashboard-analytics {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    .analytics-item {
      text-align: center;
      width: 100%;
      .box-title {
        font-size: 1.5rem;
        font-weight: 600;
        color: $primary;
        margin-top: 0.5rem;
        display: flex;
        flex-direction: row;
        span {
          padding: 0 0.5rem;
        }
        &::before,
        &::after {
          content: "";
          flex: 1 1;
          border-bottom: 2px solid $primary;
          margin: auto;
        }
      }
      .box {
        background: linear-gradient(to right, $secondary, $green-med, $primary);
        box-shadow: 0.5rem 0.5rem 15px rgb(0 0 0 / 9%);
        color: $white;
        padding: 0.5rem;
        border-radius: 0px 50px 50px 50px;
        .box-value {
          font-size: 3rem;
          font-weight: 900;
          span {
            font-size: 2rem;
            font-weight: 700;
            margin-left: 0.5rem;
          }
        }
      }
    }
  }
  #employee-dash-main-section {
    max-width: 99%;
    .page-head-container {
      padding-left: 0 !important;
    }
    #employee-dash-graph-container {
      #employee-dash-graph {
        width: 100%;
        height: 450px;
        border: 2px solid $primary;
      }
    }
  }
}
.table-title {
  display: flex;
  justify-content: space-between;
}
.graph-tooltip {
  border: 1px solid $primary;
  background-color: $background;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  text-align: center;
  .graph-tooltip-total {
    font-weight: bold;
    font-size: 1.5rem;
  }
}
@media (max-width: 768px) {
  #employee-main-page {
    grid-template-columns: 1fr !important;
  }
  .dashboard-analytics {
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: space-between;
    .analytics-item {
      width: 49% !important;
      .box {
        width: 100% !important;
      }
    }
  }
}
