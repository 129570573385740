$black: hsl(0, 0%, 4%);
$black-bis: hsl(0, 0%, 7%);
$black-ter: hsl(0, 0%, 14%);

$grey-darker: hsl(0, 0%, 21%);
$grey-dark: hsl(0, 0%, 29%);
$grey: hsl(0, 0%, 48%);
$grey-light: hsl(0, 0%, 71%);
$grey-lighter: hsl(0, 0%, 86%);
$grey-lightest: hsl(0, 0%, 98%);

$white-ter: hsl(0, 0%, 96%);
$white-bis: hsl(0, 0%, 98%);
$white: hsl(0, 0%, 100%);

$orange: #ff8831;
$yellow: #ffc107;
$yellow-light: #ffe699;
$yellow-lighter: #fff2cc;
$yellow-lightest: #fff9e6;
$pink: #cc7ea5;
// $green: hsl(141, 71%, 48%);
// $turquoise: hsl(171, 100%, 41%);
$cyan: hsl(204, 86%, 53%);
$blue: hsl(217, 71%, 53%);
$purple: #7469b8;
$red: #dc3545;

$primary: #059e42;
$secondary: #3bb78e;
$green-med: #24a864;
$green-med-faded: rgba(36, 168, 100, 50%);
$background: #f7fafd;
