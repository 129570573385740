@import "../../styles/variables";

.landing-page {
  width: 100vw;
  height: 100vh;
  background-color: $grey-lightest;
  display: flex;
  align-items: center;
  justify-content: center;
  .landing-page-container {
    width: 80vw;
    min-height: 80vh;
    height: auto;
    background-color: white;
    border-radius: 1.5rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    display: grid;
    grid-template-columns: 40% 60%;
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 5rem;
      & > p {
        margin-bottom: 40px;
      }
      &:first-child {
        background: linear-gradient(to right, $secondary, $primary);
        height: 100%;
      }
      &:nth-child(2) {
        height: 100%;
      }
    }
    #login-header {
      margin-bottom: 1rem;
    }
    .tabs {
      margin-bottom: 4rem;
      ul {
        border-bottom: none;
        .is-active {
          a {
            color: $primary;
            font-weight: bold;
          }
        }
        :hover {
          a {
            font-weight: bold;
          }
        }
      }
    }
    .landing-page-inputs {
      width: 100%;
      padding: 0 3rem;
      .field {
        &:not(:last-child) {
          margin-bottom: 1.5rem;
        }
        &:last-of-type {
          margin-bottom: 2.5rem;
        }
      }
      .button {
        border-top-right-radius: 0px;
      }
    }
  }
}
@media (max-width: 768px) {
  .landing-page {
    .landing-page-container {
      width: 95%;
      height: 95%;
      grid-template-columns: 100%;
      & > div {
        padding: 1rem;
        overflow-y: auto;
        & > h1 {
          font-size: 2rem;
        }
        & > p {
          margin-bottom: 20px;
        }
      }
      .signup-container {
        padding-top: 3rem;
      }
      .tabs {
        margin-bottom: 1rem;
      }
    }
  }
}

.forgot-password {
  a {
    color: $primary;
    &:hover {
      color: $green-med;
    }
  }
  margin-bottom: 1rem;
}
