@import "../../../../../styles/variables";

.read-only-display {
  .input[disabled] {
    color: rgb(55, 55, 55) !important;
    background-color: darken($grey-lightest, 2%) !important;
  }
  textarea[disabled] {
    color: rgb(55, 55, 55) !important;
    background-color: darken($grey-lightest, 2%) !important;
  }
}
.subtitle {
  margin-bottom: 0.5rem !important;
  font-weight: bold;
}
