.package-container {
  margin: 8px;
  display: flex;
  flex-direction: column;
  max-height: 40rem;
  width: 100%;
  padding: 0.5rem;
  flex: 0 0 30%;
  max-width: 330px;
  height: 100%;
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    background-color: #ebecf0;
    transition: background-color 0.2s ease;
    h4 {
      padding: 8px;
      transition: background-color ease 0.2s;
      flex-grow: 1;
      user-select: none;
      position: relative;
    }
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    border: 8px;
    transition: background-color 0.2s ease, opacity 0.1s ease;
    user-select: none;
    width: 100%;
    max-height: 32rem;
    padding-bottom: 10px;
    border: solid #ebecf0 0.5px;
    max-height: 30rem;
    overflow-y: auto;
    .item-container {
      border-radius: 2px;
      border: 2px solid transparent;
      padding: 8px;
      min-height: 40px;
      margin-bottom: 8px;
      user-select: none;
      /* anchor overrides */
      color: #091e42;
      /* flexbox */
      display: flex;
      .info-item {
        width: 100%;
        display: grid;
        grid-template-columns: 2fr 1.5fr auto;
        color: #7a7a7a;
        font-size: 0.8rem;
        grid-column-gap: 5px;
        .money {
          text-align: end;
          color: #079e42;
        }
      }
    }
  }
}
