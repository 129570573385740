.tablesWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem 3rem;
}

.tablesWrapper > div {
  width: 100%;
}

.my-header-class {
  .checkbox-container {
    justify-content: flex-end;
  }
  .table-group-title {
    display: inline-flex;
    justify-content: space-between;
    background-color: #efefef;
    padding: 10px 16px;
    width: 100%;
    .title {
      margin: 0.5rem;
    }
    .checkbox-container {
      max-width: 50%;
      justify-content: flex-end;
    }
  }
}
