@import "../../styles/variables";

.page-head-container {
  padding: 0.5rem 0 1.5rem 1.5rem;
}

.page-head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;

  .page-head-start {
    display: flex;
    align-items: center;
    & > *:not(:last-child) {
      margin-right: 1rem;
    }
  }

  .page-head-start--subheader {
    > * {
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }

  .title {
    font-size: 1.5rem;
  }

  .title,
  .breadcrumb {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
  }

  .title::after {
    content: '';
    border: 1px solid #059e42;
    border-radius: 4px;
    display: block;
    margin-top: 10px;
    width: 2rem;
  }

  .button {
    border-radius: 20px 0 20px 20px;
  }

  .page-head-end {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    :not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  @media (min-width: 968px) {
    .title {
      font-size: 2rem;
    }
  }
}

.label {
  font-size: 0.8rem;
}

.select:not(.is-multiple):not(.is-loading)::after {
  border-color: $primary;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 25px;
}
.password-visibility {
  position: absolute;
  right: 8px;
  top: 28px;
  cursor: pointer;
}

.select:not(.is-multiple):not(.is-loading)::after {
  border-color: #cccccc;
}
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 25px;
}
