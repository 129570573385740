.label-claim-columns-wrapper {
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 4rem;
}
.button.is-static {
  background-color: transparent;
  border: 1px solid #dbdbdb;
  border-radius: 0 5px 5px 0 !important;
}
