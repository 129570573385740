.main-table-container {
  hr {
    height: 4px;
    margin: 1px 0 1rem 0;
  }
  .row-container {
    display: grid;
    column-gap: 1rem;
    font-family: "Avenir", "Roboto", sans-serif;
    .checkbox-container {
      justify-content: flex-start !important;
    }
    &.header {
      font-size: 0.8rem;
      color: black;
    }
    &.body {
      margin: 1rem 0;
    }
  }
}
