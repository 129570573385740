.home-page-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5rem;
  .button {
    height: 4rem;
    margin: 5px 0px;
    width: 80%;
  }
}

.home-container {
  display: grid;
  grid-template-columns: 1fr 4fr;
}
@media (max-width: 768px) {
  .home-container {
    grid-template-columns: 1fr !important;
  }
  .home-page-options {
    padding-top: 0;
  }
}
.private-label-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.home-page-main-content {
  overflow-x: auto;
}
