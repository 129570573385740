#edit-job-order-form {
  .columns .read-only-display-wrapper {
    padding: 0;
    @media only screen and (min-width: 1024px) {
      padding: 0.75rem;
    }
    .box {
      display: flex;
      flex-direction: column;
      @media only screen and (min-width: 1024px) {
        flex-direction: row;
      }
    }
  }

  .box {
    padding: 0;
    box-shadow: none;
    margin-bottom: 0;
    @media only screen and (min-width: 1024px) {
      padding: 1.25rem;
      box-shadow: 1px 2px rgb(0 0 0 / 9%);
    }
  }
  .file-column {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    @media only screen and (min-width: 1024px) {
      flex-direction: row;
    }
    // .control {
    //   &:first-child {
    //     margin-right: 0;
    //     @media only screen and (min-width: 1024px) {
    //       margin-right: 0.75rem;
    //     }
    //   }
    //   &:last-child {
    //     margin-top: 10px;
    //     align-self: center;
    //     @media only screen and (min-width: 1024px) {
    //       margin-top: 0;
    //     }
    //   }
    // }
  }

  .file-column > div {
    flex-basis: 50%;
  }

  .important-wrapper {
    margin-left: 1.5%;
  }

  .picture-wrapper {
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: 1024px) {
      flex-direction: row;
    }
  }

  .picture-wrapper > .control:nth-child(2) {
    display: flex;
    padding: 10px;
    padding: 0;
    @media only screen and (min-width: 1024px) {
      flex-direction: row;
      padding-left: 40px;
    }
  }

  .order-info-column {
    .columns {
      .column {
        &:first-child,
        &:last-child {
          .box {
            .field.is-grouped {
              .control.is-expanded {
                min-width: 49%;
                @media screen and (min-width: 1024px) {
                  min-width: 50%;
                }
              }
            }
          }
        }
      }
    }
    .async-select {
      div {
        &:nth-child(2) {
          min-width: unset;
        }
      }
    }
  }
  .has-tooltip-danger {
    border: none;
  }
}
