.equipment-columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.8rem;
}

.equipment-edit-form-columns .switch-wrapper {
  display: flex;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10rem;
}

.equipment-edit-form-columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.8rem;
}

@media only screen and (min-width: 667px) {
  .equipment-columns {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .equipment-edit-form-columns {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 968px) {
  .equipment-columns {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}
