@import "../../../../../../styles/variables";

.import-results-drop-zone {
  width: 100%;
  padding: 4rem;
  border: 1px solid $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 250ms;
  border-radius: 4px;
  &:hover {
    background: lighten($primary, 65%);
  }
  .import-results-drop-zone-content {
    display: flex;
    flex-flow: column;
    align-items: center;
    p {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
}
