@import "../../styles/variables";

$menu-width: 295px;

.navigation-bar {
  position: fixed;
  left: 0;
  z-index: 999;
  background: linear-gradient(to right, $secondary, $primary);
  height: 3.25rem;
  width: 100%;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.09);
  & > div {
    height: 100%;
    position: relative;
  }
  .container {
    height: 100%;
  }
  .navigation {
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    .navigation-start {
      margin-left: $menu-width;
      justify-self: flex-start;
    }
    .navigation-end {
      justify-self: flex-end;
      .badge {
        font-size: 10px;
        background: $white;
        border-radius: 50%;
        padding: 3px;
        position: relative;
        right: -1rem;
        top: -1rem;
        height: 20px;
        width: 20px;
        color: $primary;
        font-weight: bold;
        border: solid 1px;
        text-align: center;
      }
    }
    .navigation-item {
      .hamburger {
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &--brand {
        color: white;
        font-weight: bolder;
        letter-spacing: 2px;
        font-size: 1.75rem;
      }
      .nested-menu {
        overflow-y: auto;
        position: fixed;
        top: 0;
        height: 100vh;
        left: $menu-width;
        width: 320px;
        @media screen and (max-width: 900px) {
          left: 0;
          width: 90%;
          z-index: 9999;
        }
        bottom: 0;
        padding: 0.5rem 1rem;
        visibility: hidden;
        transform: translate3d(-100%, 0, 0);
        transition: 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
        background: #373a47;
        color: white;
        box-shadow: 1px 0px 4px 0px hsla(0, 0%, 0%, 0.15);
        letter-spacing: 1px;
        font-weight: 400;
        font-size: 1.25rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 4rem;
        .nested-menu-title {
          text-align: left;
          text-transform: uppercase;
          letter-spacing: 1px;
          margin: 0.5rem 0;
          display: grid;
          grid-template-columns: 1fr auto;
          .close-nested-menu {
            display: none;
          }
          @media (max-width: 768px) {
            .close-nested-menu {
              display: block;
            } 
          }
        }

        .nested-menu-container {
          display: flex;
          flex-direction: column;

          &.deep {
            &.depth-2 {
              padding-left: 1rem;
            }
            .nested-menu-item {
              position: relative;
              padding-left: 1.15rem;

              &:before {
                content: "";
                width: 6px;
                height: 6px;
                overflow: hidden;
                background: $primary;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(-50%, -50%);
                border-radius: 50%;
                margin: 0 0.5rem;
              }
            }
          }
          .nested-menu-item {
            user-select: none;
            span {
              color: white;
            }
            appearance: none;
            letter-spacing: 1px;
            font-weight: 400;
            margin: 0.25rem 0.5rem;
            color: $white !important;

            width: 100%;
            display: flex;

            align-items: center;
            font-size: 1rem;
            cursor: pointer;

            &:hover {
              color: $grey;
            }
            // &:last-child {
            //   margin-bottom: 1.5rem;
            // }
            span:not(:last-child) {
              flex: 1;
            }
            span:not(:first-child) {
              padding-right: 15px;
            }
          }
        }
        &.is-active {
          visibility: visible;
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
}

.container.is-fluid {
  padding-left: 1rem;
}

@media (max-width: 1023px) {
  .container.is-fluid {
    padding-right: 1rem;
  }
}
.sidebar-header {
  height: 130px;
  .sidebar-header-item {
    width: 100%;
    &__brand {
      padding: 2rem 0;
      img {
        height: 64px;
        display: block;
        margin: 0 auto;
      }
    }
  }
}

.sidebar-menu {
  width: $menu-width;
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 1.2rem;
  overflow-y: auto;
  height: calc(100vh - 200px);
  .sidebar-menu-item {
    user-select: none;
    outline: none;
    padding: 0.25rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    color: $primary;
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    justify-content: flex-start;

    transition: background-color 250ms;

    span {
      &:nth-child(2) {
        color: $grey-dark;
        padding-left: 1rem;
      }
    }
    &.bottom {
      width: 100%;
      margin-top: 2rem;
    }
    &:hover {
      background-color: $green-med-faded;
    }
    &.is-current {
      background-color: $green-med;
      color: $grey-lightest;
      span {
        color: $grey-lightest;
      }
    }
    &.is-active {
      background-color: $green-med-faded;
    }
  }
}
.user-actions-dropdown {
  .dropdown {
    .button {
      font-size: 1rem;
      border: none;
      padding-left: 1rem;
      padding-right: 1rem;
      div {
        margin: 0 0.75rem;
      }
    }
  }
  .dropdown-item {
    display: flex;
    align-items: center;
  }
}
.arrow-right {
  width: 0;
  height: 0;
  border-top: 1rem solid transparent;
  border-bottom: 1rem solid transparent;
  border-left: 1rem solid white;
}

.sidebar-title {
  text-align: center;
  color: $primary;
  margin-bottom: 5px !important;
}

.sidebar-subtitle {
  text-align: center;
  color: $grey;
  font-size: 0.8rem;
  margin-bottom: 1rem;
}

.header-container {
  display: grid;
  grid-template-columns: 1fr 40px;
  color: $primary;
  .icon {
    width: 2rem;
  }
}

.credit-ledger {
  display: grid;
  grid-template-columns: 1fr auto;
  color: $grey;
  padding: 0 10px;
  border-bottom: solid 0.2px #f7f4f4;
  margin-bottom: 5px;
  .money {
    color: $primary !important;
  }
}