#analyte-table {
  .tablesWrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    @media screen and (min-width: 667px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .tablesWrapper > div {
    width: 100%;
  }

  .checkbox-container {
    justify-content: flex-end;
  }
  .table-group-title {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    .title {
      margin: 0.5rem;
    }
  }
}
