.action-limit-list-page {
  #action-limit-template-form {
    padding: 1rem;

    .columns {
      .column {
        padding-right: 0;
        .field:nth-child(2) {
          flex-wrap: wrap;
        }
      }
    }

    .button {
      border-radius: 20px 0 20px 20px;
    }
  }
  .tablesWrapper {
    grid-template-columns: 1fr;
    gap: 1rem;
    @media screen and (min-width: 667px) {
      grid-template-columns: 1fr 1fr;
    }
  }
  .table-section .label {
    align-self: center;
  }
}
