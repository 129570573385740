@import "variables";

@import "bulma";
@import "@creativebulma/bulma-tooltip";
@import "~rbx/rbx";
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

*,
*:before,
*:after {
  font-family: "Avenir", "Roboto", sans-serif;
}

html {
  overflow-y: hidden;
}

$menu-width: 295px;

@media screen and (min-width: 1024px) {
  main.container {
    max-width: inherit;
    background-color: $background;
  }
}

@media screen and (min-width: 769px) {
  main.container {
    width: 100%;
    margin-left: 0px !important;
    background-color: $background;

    .main-page-container {
      transition: padding 0.3s linear;
    }
    &.open {
      // width: calc(100% - 295px) !important;
      .main-page-container {
        padding-left: calc(2rem + 295px) !important;
      }
      nav {
        transform: translate3d(0, 0, 0);
      }
    }
  }
}

.main-page-container {
  padding-top: 60px;
}

@media screen and (max-width: 900px) {
  .main-page-container {
    &.open {
      opacity: 0.5;
    }
  }
}

.section-container {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 4rem 1.5rem 1.5rem 1.5rem;
  width: 100%;
  .section-header {
    display: flex;
    flex-direction: row;
  }
}

.tabs.is-boxed ul li.is-active button {
  background: $primary;
  color: white;
  border: 1px solid $primary;
}

.tabs.is-boxed ul li {
  a {
    padding: 0.5rem 1rem !important;
  }
}

.tabs {
  border-bottom: 1px solid $primary;
  margin-bottom: 1.5rem;
  &.is-boxed {
    ul {
      border: none;
      font-weight: 500;
      font-size: 1rem;
      li {
        margin-bottom: -1px;
        font-size: 0.8rem;

        a {
          border: 1px solid transparent;
          color: $primary;
          padding: 0.5rem 1.5rem;
          text-transform: uppercase;
        }
        &.is-active {
          a {
            background: $primary;
            color: $white;
            border: 1px solid $primary;
            border-radius: 10px 10px 0px 0px;
          }
          &:hover {
            -webkit-text-stroke-width: 0.06ex;
            -webkit-text-stroke-color: $white;
          }
        }
        &:hover {
          -webkit-text-stroke-width: 0.06ex;
          -webkit-text-stroke-color: $primary;
        }
      }
    }
  }
}

.tag.is-warning {
  background: $yellow !important;
  color: $black !important;
}
.tag.is-error {
  background: $red !important;
  color: $white !important;
}
.tag.is-purple {
  background: $purple !important;
  color: $white !important;
}
.tag.is-cyan {
  background: $cyan !important;
  color: $white !important;
}
.tag.is-pink {
  background: $pink !important;
  color: $white !important;
}
.tag.is-orange {
  background: $orange !important;
  color: $white !important;
}
.tag.is-grey {
  background: $grey-light !important;
  color: $black !important;
}

.icon.has-text-info {
  color: $orange !important;
}

.modal {
  &.is-active {
    z-index: 999;
  }
  .modal-content {
    width: 90%;
    max-width: 900px;
  }
}

.button.is-static {
  background-color: transparent;
  border: none;
}

.button.is-primary {
  text-align: center;
  border-top-right-radius: 0px;
  background: linear-gradient(to right, $secondary, $primary);
  &:hover {
    background: linear-gradient(
      to right,
      lighten($secondary, 5%),
      lighten($primary, 5%)
    );
  }
}

.button.is-secondary {
  border-top-right-radius: 0px !important;
  color: $white;
  background: linear-gradient(to right, lighten($purple, 5%), $purple);
  &:hover {
    color: $white;
    background: linear-gradient(
      to right,
      lighten($purple, 8%),
      lighten($purple, 5%)
    );
  }
}
.button.is-danger {
  border-top-right-radius: 0px !important;
  color: $white;
  background: linear-gradient(to right, lighten($red, 5%), $red);
  &:hover {
    color: $white;
    background: linear-gradient(
      to right,
      lighten($red, 10%),
      lighten($red, 5%)
    );
  }
}
.button.is-warning {
  border-top-right-radius: 0px !important;
  background: linear-gradient(to right, lighten($yellow, 5%), $yellow);
  &:hover {
    background: linear-gradient(
      to right,
      lighten($yellow, 10%),
      lighten($yellow, 5%)
    );
  }
}

.box {
  box-shadow: 1px 2px rgba(0, 0, 0, 0.09);
  border-radius: 0px 20px 20px 20px;
  .button {
    border-radius: 20px 0px 20px 20px;
  }
}

.is-small.input,
.select.is-small {
  border-radius: 5px;
}

hr {
  background-color: $primary;
}

.input[readonly] {
  background: transparent;
}
input:read-only {
  border: none;
}

.fa-skull {
  color: $red;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.tabs.is-boxed ul {
  li .tag {
    padding: 0.5rem 1rem !important;
    opacity: 0.5;
    height: 35px;
    border: none;
    cursor: pointer;
  }
  li.is-active .tag {
    border-color: #dbdbdb;
    border-bottom-color: transparent !important;
  }
  li.is-active .tag {
    opacity: 1;
    color: white;
    border: none;
  }
  li .tag.is-warning {
    color: black;
    &:hover {
      color: black;
      -webkit-text-stroke-width: black !important;
      -webkit-text-stroke-color: black !important;
    }
  }
  li .tag.is-success,
  .is-error,
  .is-primary,
  .is-secondary {
    color: white;
    &:hover {
      color: white;
      -webkit-text-stroke-width: white !important;
      -webkit-text-stroke-color: white !important;
    }
  }
}

.field {
  &.grid-override {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0.75rem;

    &.is-three {
      grid-template-columns: 1fr 1fr 1fr;
    }
    &.is-four {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    &.is-five {
      grid-template-columns: repeat(5, 1fr);
    }
    &.is-six {
      grid-template-columns: repeat(6, 1fr);
    }
    .control.is-expanded {
      margin: 0;
    }
  }
}
