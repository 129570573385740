@import "../../../../../../styles/variables";

#homogeneity-results-container {
  .homogeneity-results-row {
    display: grid;
    grid-template-columns: 1fr repeat(4, 3fr);
    div {
      text-align: center;
      &.Pass {
        color: $primary;
      }
      &.Fail {
        color: $red;
      }
    }
  }
  .homogeneity-results-heading {
    border-bottom: 2px solid $primary;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
}
