.title-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.7em;
}

.subtitle-wrapper > p {
  padding: 10px 0;
  font-size: 1.2em;
}

.individual-test-wrapper {
  display: flex;
  padding: 10px 0;
}

.individual-test-content-wrapper {
  padding: 7px 5px;
  font-size: 1.1em;
  line-height: 37px;
}
