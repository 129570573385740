.notes-wrapper {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: 15px;
}

.notes-wrapper > div {
  margin-top: 5px;
}

.note-metadata {
  font-weight: bold;
}
