.equipment-tests-container {
  display: grid;
  grid-template-columns: 1fr;
}

@media only screen and (min-width: 667px) {
  .equipment-tests-container {
    grid-template-columns: 1fr 1fr;
  } 
}

@media only screen and (min-width: 968px) {
  .equipment-tests-container {
    grid-template-columns: 1fr 1fr 1fr;
  } 
}