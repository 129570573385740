@import "../../styles/variables";

@media (max-width: 900px) {
  .cart-grid {
    display: block !important;
    .summary-box {
      padding: 1rem !important;
    }
  }
}
.cart-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  .cart-details {
    background-color: white;
    border-radius: 0px 20px 20px 20px;
    padding: 10px;
    width: 100%;
    .cart-item {
      padding: 10px;
      max-height: 150px;
      border-radius: 20px 0px 20px 20px;
      border: 1px solid $primary;
      margin: 10px 5px;
      -moz-transition: max-height 0.5s ease-out;
      -webkit-transition: max-height 0.5s ease-out;
      -o-transition: max-height 0.5s ease-out;
      transition: max-height 0.5s ease-out;
      overflow: hidden;
      cursor: pointer;
      h1 {
        margin: 5px 0;
      }
      div {
        display: none;
      }
      .cart-detailed-information {
        display: none;
      }
      .show {
        display: block;
        .cart-detailed-information {
          padding-left: 1rem;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-column-gap: 3px;
          font-size: 12px;
          border-bottom: 1px solid $primary;
          margin-bottom: 0.5rem;
          padding-bottom: 0.5rem;
          padding-top: 0.5rem;
        }
        .table {
          width: 100%;
          font-size: 13px;
          margin-top: 1rem;
          thead {
            background-color: #f7f4f4;
          }
        }
      }
      .cart-item-header {
        display: grid;
        grid-template-columns: 20px 1fr 1fr auto auto;
        grid-column-gap: 0.8rem;
      }
    }
    .cart-item-opened {
      max-height: 500px;
      transition: max-height 0.5s ease-in;
    }
  }
  div {
    .box {
      border-radius: 20px 0px 20px 20px !important;
      border: 1px solid #059e42 !important;
      height: 100%;
    }
  }
  .summary-box {
    padding: 5rem;
  }
}

.green {
  color: $primary;
}

.rounded-button {
  border-radius: 20px 0px 20px 20px;
}

.confirmation-container {
  border: solid 10px $secondary;
  border-radius: 6px;
  display: block;
  padding: 1.25rem;
  .button-container {
    text-align: center;
    margin-top: 1rem;
  }
}
