@import "../../../../styles/variables";

.pkg-container {
  min-height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  .column {
    margin: 8px 0 8px 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0.5rem;
    flex: 1 0 21%;
    min-width: 300px;
    max-width: 435px;
    .header {
      display: grid;
      grid-template-columns: repeat(2, fr);
      align-items: center;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      background-color: #f1f1f1;
      transition: background-color 0.2s ease;
      border-radius: 0px 20px 0px 0px;
      padding: 0.75rem 1rem 0.5rem 1rem;
      border-bottom: solid 1px #e0dede !important;
      &.is-selected {
        border: 1px solid $primary;
      }
      .title {
        transition: background-color ease 0.2s;
        flex-grow: 1;
        user-select: none;
        position: relative;
        font-size: 20px;
        margin: 0;
        grid-column: span 2;
        text-align: center;
      }
      .money {
        color: #079e42;
      }
      .button {
        border-radius: 20px 0 20px 20px;
      }
    }
    .col-container {
      border-radius: 0px 0px 20px 20px;
      background-color: #f1f1f1;
      display: flex;
      flex-direction: column;
      max-height: 40rem;
      width: 100%;
      padding: 0.5rem;
      flex: 1 0 21%;
      max-width: 435px;
      height: 100%;
      overflow-y: scroll;
      .test-info-item {
        width: 100%;
        display: grid;
        grid-template-columns: 5fr 1fr auto;
        color: #7a7a7a;
        background-color: #ffffff;
        font-size: 0.8rem;
        grid-column-gap: 5px;
        border-radius: 20px 0px 20px 20px;
        border: 1px solid $primary;
        margin: 10px 0px;
        padding: 10px;
        cursor: default;
        .money {
          text-align: end;
          color: #079e42;
        }
        p {
          white-space: inherit;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 1.5rem;
        }
      }
      &.is-selected {
        border: 1px solid $primary;
        border-top: none;
      }
    }
  }
}
