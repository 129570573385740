@import "../../../../styles/variables";

.payment-form-container .credit-container {
  text-align: end;
  margin-bottom: 10px;
  padding-bottom: 15px;
  .additional {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }
}

.tabs.is-boxed ul li button {
  align-items: center;
  border-bottom-color: #dbdbdb !important;
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  color: $primary !important;
  display: flex !important;
  justify-content: center !important;
  margin-bottom: -1px !important;
  padding: 0.5em 1em !important;
  vertical-align: top !important;
  border-color: transparent !important;
  border-width: 0px !important;
  border-radius: 0px !important;
}

.tabs.is-boxed ul li button.active {
  background-color: $primary !important;
  color: $white !important;
  border: 1px solid $primary !important;
  border-radius: 5px 5px 0px 0px !important;
}

iframe.sq-card-component {
  height: 48px;
}

#card-input-container {
  text-align: center;
}
