#reprep-btn-group {
  .button {
    border-radius: 15px 0 0 15px;
  }
  .select {
    select {
      border-radius: 0 0 15px 0 !important;
    }
  }
}
