#date-range-select {
  display: flex;
  width: 100% !important;
  .field {
    width: 100% !important;
    .react-datepicker-wrapper {
      width: 100% !important;
      input {
        width: 100% !important;
        border-radius: 5px;
        box-shadow: inset 0 1px 2px rgb(10 10 10 / 10%);
        border: 1px solid transparent;
        border-color: #dbdbdb;
        line-height: 1.4rem;
      }
    }
    &:last-child {
      margin-left: 1.5rem;
    }
  }
}
