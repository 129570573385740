@import "../../styles/variables";

.new-test-list-page {
  .new-test-form {
    margin-bottom: 30px;
    span {
      &.icon {
        padding-left: 0.5rem;
      }
    }
    .test-container {
      display: grid;
      grid-template-columns: 0.4fr 0.4fr;
      background-color: white;
      border-radius: 0px 20px 20px 20px;
      padding: 10px;
      justify-content: center;
      .test-item {
        padding: 5px;
        display: grid;
        max-height: 60px;
        grid-template-columns: 7fr 1fr 1fr;
        grid-column-gap: 0.8rem;
        border-radius: 20px 0px 20px 20px;
        border: 1px solid $primary;
        margin: 10px 5px;
        -moz-transition: max-height 0.15s ease-out;
        -webkit-transition: max-height 0.15s ease-out;
        -o-transition: max-height 0.15s ease-out;
        transition: max-height 0.15s ease-out;
        overflow: hidden;
        h1 {
          margin: 5px 0;
          cursor: pointer;
        }
        p {
          display: none;
        }
        .show {
          display: block;
        }
      }
      .test-item-opened {
        max-height: 500px;
        transition: max-height 0.25s ease-in;
      }
      .package-container {
        padding: 1em;
        margin: 1em;
        .active {
          background: $primary !important;
          color: white !important;
          span {
            color: white !important;
          }
        }
        .test-item {
          width: 100%;
          cursor: pointer;
          span {
            text-align: end;
          }
        }
      }
    }
    @media (max-width: 768px) {
      .test-container {
        grid-template-columns: 1fr !important;
      }
    }
  }
}

.page-header {
  padding-left: 0;
  position: sticky;
  width: 100%;
  z-index: 998;
  background: #f7fafd;
  left: 0;
}

@media (min-width: 824px) {
  .page-header {
    top: -15px;
  }
}
@media (max-width: 768px) {
  .page-header {
    width: 100%;
  }
}
