@import "../../../../styles/variables";

#add-batch {
  .checkbox-container {
    div {
      margin-top: 0 !important;
    }
  }
}
.highlighted-row {
  background-color: $green-med-faded;
}
.qc-note {
  font-size: 0.75rem;
  text-align: right;
  padding-top: 1rem;
  color: $red;
}
.tests-container {
  .show-more-tests-button {
    cursor: pointer;
    text-align: center;
    &:hover {
      cursor: pointer;
    }
  }
  .center {
    text-align: center;
  }
}
.test-item-container {
  display: grid;
  grid-column-gap: 5px;
  margin: 5px 0px;
}
.grid-5 {
  grid-template-columns: auto auto repeat(3, 1fr);
}
.grid-6 {
  grid-template-columns: auto auto repeat(4, 1fr);
}
.grid-4 {
  grid-template-columns: auto repeat(3, 1fr);
}
.grid-2 {
  grid-template-columns: auto repeat(2, 1fr);
}
.title-grid-4 {
  grid-template-columns: repeat(4, 1fr);
}
.title-grid-3 {
  grid-template-columns: repeat(3, 1fr);
}
.title-grid-2 {
  grid-template-columns: repeat(2, 1fr);
}
.buttons-grid-4 {
  display: grid;
  grid-column-gap: 5px;
  grid-template-columns: repeat(4, 1fr);
}

.batch-header {
  display: flex;
  .tag {
    margin: 0.3rem 0 0 15px;
  }
}
#batch-results-container {
  width: 100%;
  height: 70vh;
  overflow-x: scroll;
  padding: 0 0 1rem 0;
  .column {
    width: 300px;
  }

  .result-item {
    height: 1.5rem;
    border-bottom: 2px solid $grey-lightest;
    overflow-y: visible !important;
    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    background-color: white;
    padding: 0 1rem 0 1rem;
    color: $grey-dark;
    .input {
      font-size: 0.8rem;
      overflow-x: scroll;
    }
    .dropdown {
      width: 100%;
      position: relative;
      .dropdown-menu {
        z-index: 8;
        position: absolute;
        top: -0.25rem;
        width: 100%;
        background-color: transparent !important;
        .dropdown-content {
          background-color: $yellow-lightest !important;
          width: 100%;
          padding: 0;
          .dropdown-item {
            color: $black !important;
            border-radius: 0 0 5px 5px;
            font-size: 0.8rem;
            &:hover {
              background-color: $yellow-light;
            }
          }
          icon.dropdown-item {
            background-color: transparent !important;
            width: fit-content;
            position: absolute;
            top: 0;
            right: 0;
            color: $red !important;
            padding-right: 0.5rem;
          }
          input.dropdown-item {
            background-color: $yellow-lighter !important;
            border-radius: 5px 5px 0 0;
            padding-bottom: 0 !important;
            width: 100%;
            outline: none !important;
            border: none !important;
            &::placeholder {
              color: $grey !important;
              font-style: italic;
            }
          }
          .dropdown-divider {
            margin: 0 !important;
          }
        }
      }
    }
    &.blur {
      filter: blur(1px);
      transition: filter 1s;
    }
  }
  .result-item::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }

  .results-column {
    padding: 0 3px 0 0;
    &.alternate-background {
      .result-item {
        background-color: #ebf2ec;
      }
    }
    &.border-left {
      border-left: 2px solid $green-med-faded;
    }
    &.border-right {
      border-right: 2px solid $green-med-faded;
    }
  }

  .header-column {
    border-right: 3px solid $primary;
    margin: 0;
    padding: 0;
    position: sticky;
    left: 0;
    z-index: 99;
    .result-item {
      display: flex;
      justify-content: space-between;
      padding-right: 1rem;
      background-color: white;
      border-color: $background;
      padding-left: 2rem;
    }
  }

  .field {
    margin: 0;
    .control {
      padding: 0 !important;
    }
    .input {
      border: none;
      box-shadow: none;
      background-color: transparent !important;
      width: 90%;
      padding: 0 !important;
      width: 100%;
    }
    .button {
      border: none !important;
      border-color: transparent !important;
      background-color: transparent;
      outline: 0 !important;
    }
    .button:focus {
      border-radius: 20px !important;
      border: none !important;
      border-color: transparent !important;
      outline: hidden !important;
    }
  }

  .result-item {
    .header-name {
      height: 4rem;
      white-space: wrap;
      font-size: 0.8rem;
      border-bottom: 2px solid $background;
      padding: 1rem;
      font-weight: bold;
    }
    .header-buttons {
      height: 4rem;
      border-bottom: 2px solid $background;
      padding: 0.5rem;
      display: flex;
      justify-content: space-evenly;
      .result-status {
        font-size: 0.75rem;
        width: 80%;
        text-align: center;
        margin: auto;
        display: flex;
        justify-content: center;
        .button {
          margin-left: 1rem;
          border-color: transparent;
        }
        .status-by {
          margin-top: 0.5rem;
          margin-left: 0.5rem;
        }
        .control {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          .label {
            margin: 0px;
          }
          .checkbox-container {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
    .header-text {
      height: 1.5rem;
      white-space: wrap;
      font-size: 0.8rem;
      border-bottom: 2px solid $background;
      padding: 0.2rem 1rem 0 1rem;
    }
    &.default-header {
      height: 12.5rem;
      padding: 0;
      margin-bottom: 0.5rem;
      width: 100%;
      position: sticky;
      top: 0;
      z-index: 9;
      text-overflow: wrap;
      .input {
        text-overflow: wrap;
      }
    }
    &.micro-header {
      height: 14.05rem;
      padding: 0;
      margin-bottom: 0.5rem;
      width: 100%;
      position: sticky;
      top: 0;
      z-index: 9;
      text-overflow: wrap;
      .input {
        text-overflow: wrap;
      }
    }
    &.fail {
      .input {
        color: $red !important;
        font-weight: bold;
      }
    }
    &.italics {
      .input {
        font-style: italic;
      }
    }
    &.pass {
      .input {
        color: $blue !important;
      }
    }
    &.black-out {
      background-color: $grey !important;
      border-color: $grey !important;
      .input {
        color: $grey !important;
      }
    }
    .input {
      &.grey {
        color: $grey !important;
        text-align: right;
        font-weight: normal;
      }
      &.primary {
        color: $primary !important;
        text-align: right;
        font-weight: normal;
      }
      &.purple {
        color: $purple !important;
        text-align: right;
        font-weight: normal;
      }
    }
    &.border-bottom {
      border-bottom: 1px solid $green-med-faded;
      height: 4rem;
      padding-top: 2rem;
    }
    &.editing {
      background-color: $yellow !important;
    }
    &.bold {
      .input {
        font-weight: bold;
      }
    }
    &.first {
      height: 3rem;
      padding-top: 1.5rem;
    }
    &.last {
      height: 3rem;
      padding-bottom: 1.5rem;
    }
    &.divider-row,
    &.single-divider {
      background-color: $background !important;
      height: 2.5rem;
      padding-top: 0.5rem;
      &.raw-micro {
        background-color: $background !important;
        height: 2.7rem;
        padding-top: 0.5rem;
      }
    }
  }
  .header-column {
    .result-item {
      background-color: white !important;
      border-color: $background !important;
      &.border-bottom {
        border-bottom: 1px solid $green-med-faded;
      }
      &.divider-row {
        position: sticky;
        color: $primary;
        width: 100vw;
        background-color: $background !important;
        display: flex;
        justify-content: center;
        font-size: 1rem;
        font-weight: bold;
        z-index: 99;
      }
      &.single-divider {
        color: $primary;
        width: 200%;
        background-color: $background !important;
        display: flex;
        justify-content: center;
        font-size: 1rem;
        font-weight: bold;
        z-index: 99;
      }
      &.divider-row {
        &.raw {
          top: 12.5rem;
        }
        &.raw-micro {
          top: 14.05rem;
        }
        &.calculated {
          top: 14.5rem;
          bottom: -1rem;
        }
        &.calculated-summed {
          top: 14.5rem;
          bottom: 1rem;
        }
        &.calculated-micro {
          top: 16rem;
          bottom: -1rem;
        }
        &.summed {
          top: 16.5rem;
          bottom: -1rem;
        }
      }
    }
    .result-item-header {
      display: block;
      font-weight: bold;
      .header-div {
        border-bottom: 2px solid $background;
        height: 1.5rem;
        padding-left: 2rem;
      }
      .header-buttons {
        padding-left: 0px;
        height: 4rem;
        border-bottom: 2px solid $background;
        display: flex;
        justify-content: space-evenly;
        .result-status {
          padding: 0;
          border-bottom: none !important;
        }
        .field,
        .control {
          border-bottom: none !important;
        }
        #qualifier-toggle {
          margin-left: 1rem;
        }
      }
      .header-name-label {
        height: 4rem;
        padding-top: 1rem;
      }
    }
  }
}
