.individual-label-wrapper {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  flex-wrap: nowrap;
  white-space: nowrap;
  padding-left: 10px;
  img {
    height: 1.5in;
    width: 1.5in;
    padding-bottom: 10px;
  }
  label.label {
    padding-bottom: 10px;
    height: 1.5in;
    width: 1.5in;
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
    flex-wrap: nowrap;
  }
}

.code-label {
  font-size: 1.5rem;
  white-space: nowrap;
}

.labels-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
}

.label-repeat-grid {
  min-height: 100%;
  &.horizontal {
    display: flex;
    flex-wrap: nowrap;
  }
  .item {
    border: solid 0.5px #f9f1f1;
  }
}
@media print {
  .section-container {
    height: inherit !important;
    padding: 0 !important;
  }
}
