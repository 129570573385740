.user-group-form {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 1rem;
  .span-2 {
    grid-column: span 2;
  }
  .span-3 {
    grid-column: span 3;
  }
}
