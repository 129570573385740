.emailInputContainer {
  display: flex;
}

.emailWrapper {
  width: 100%;
}

.plusWrapper {
  margin-left: 5px;
  margin-bottom: 2px;
}
