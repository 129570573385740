@import "../../../../../styles/variables";

.read-only-display {
  .input[disabled] {
    color: rgb(55, 55, 55) !important;
    background-color: darken($grey-lightest, 2%) !important;
  }
  textarea[disabled] {
    color: rgb(55, 55, 55) !important;
    background-color: darken($grey-lightest, 2%) !important;
  }
}
.subtitle {
  margin-bottom: 0.5rem !important;
  font-weight: bold;
}

.intake-order-tabs {
  ul {
    width: 100%;
    flex-wrap: wrap;
  }
}

[data-reach-menu-item] {
  border-right: 0.1px solid gray;
  border-left: 0.1px solid gray;
  cursor: pointer;
  padding: 0px 2px;
  background-color: white;
  font-size: 12px;
  &:hover {
    background-color: lightgray;
  }
  &:first-child {
    margin-top: 5px;
    border-top: 0.1px solid gray;
    border-radius: 5px 5px 0px 0px;
  }
  &:last-child {
    border-bottom: 0.1px solid gray;
    border-radius: 0px 0px 5px 5px;
  }
}