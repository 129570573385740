.accordion-column {
  .rc-collapse {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
    .rc-collapse-content {
      overflow: visible !important;
    }
  }
  .panel-columns-wrapper {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 1024px) {
      flex-direction: row;
    }
  }

  .page-head {
    margin-bottom: 10px;
    padding-right: 10px;
    justify-content: flex-end;
  }

  .ethanol-input {
    margin-top: 10px;
  }

  .mmtc-column-1 .field.grouped-field-on-tablet {
    .control.is-expanded:first-child {
      min-width: 49%;
    }
  }

  .mmtc-column-2 .field.grouped-field-on-tablet {
    .radio-with-memory .radio {
      margin-left: 0.5em;
    }
  }

  .order-info-column {
    .field.is-grouped {
      .control.is-expanded {
        &:first-child {
          min-width: 49%;
        }
      }
    }
  }

  .thc-info-column {
    .field.is-grouped {
      &:first-child,
      &:nth-child(2) {
        .control.is-expanded {
          &:first-child,
          &:nth-child(2) {
            min-width: 49%;
          }
        }
      }
    }
  }

  .unit-calc-column {
    display: flex;
    gap: 10px;
    .grouped-field-on-tablet {
      flex-grow: 1;
    }
  }

  .grouped-field-on-tablet {
    & > .control.is-expanded {
      margin-bottom: 10px;
    }
    @media screen and (min-width: 667px) {
      display: flex;
      justify-content: flex-start;
      & > .control.is-expanded {
        flex-grow: 1;
        flex-shrink: 1;
      }
      & > .control:not(:last-child) {
        margin-bottom: 0;
        margin-right: 0.75rem;
      }
    }
  }
}
