.test-info-item {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  color: #7a7a7a;
  font-size: 0.8rem;
  cursor: move;
  grid-column-gap: 5px;
  .money {
    text-align: end;
    color: #079e42;
  }
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5rem;
  }
}

.info-items-header {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  width: 100%;
  border-bottom: solid 4px rgb(55, 181, 137);
  height: 25px;
  h1 {
    font-size: 0.8rem;
    font-weight: 400;
  }
}

.test-item-cointainer {
  &:hover {
    background: whitesmoke !important;
  }
}

.delete-package-button {
  cursor: pointer;
  span {
    &:hover {
      color: #236141 !important;
    }
  }
}

#coa-logo-btn {
  border-radius: 20px 0 20px 20px;
  margin: 0.5rem 1rem;
}
