@import "../../styles/variables";
.sweet-loading {
  display: flex;
  height: 80vh;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.corners {
  width: 20vh;
  height: 20vh;
  position: relative;
  transform-origin: center;
  animation: spin 3s infinite linear;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

.corner {
  width: 100%;
  height: 100%;
  position: absolute;

  &::before {
    display: block;
    width: 48%;
    height: 48%;
    border-radius: 0 40% 0 40%;
    background-color: $primary;
    content: "";
  }
}

@keyframes spin1 {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(0deg);
  }
  70% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin2 {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(270deg);
  }
  70% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin3 {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(180deg);
  }
  70% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin4 {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(90deg);
  }
  70% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.corner--1 {
  animation: spin1 3s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.corner--2 {
  animation: spin2 3s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.corner--3 {
  animation: spin3 3s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.corner--4 {
  animation: spin4 3s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
