@import "../../../../styles/variables";

#notification-modal {
  text-align: center;
  .title {
    background-color: $green-med-faded;
    padding: 1rem;
    margin: 0;
    border-radius: 0 20px 0 0;
  }
  .notification-container {
    border: 1px solid $primary;
    border-radius: 0 0 20px 20px;
    padding: 1rem;
    p {
      font-size: 1.2rem;
      font-weight: bold;
    }
  }
}
