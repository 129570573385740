.employee-proficiencies-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

@media only screen and (min-width: 667px) {
  .employee-proficiencies-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}
