.import-results-file-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  margin: 1rem auto;
  .import-results-file-list-item {
    border: 1px solid red;
    padding: 0.75rem;
  }
}

.result-container {
  border-radius: 20px 0px 20px 20px;
  border: 1px solid #059e42;
  background-color: #ffffff;
  box-shadow: none;
  padding: 0px 8px;
  min-height: 30px;
  user-select: none;

  /* anchor overrides */
  color: #091e42;

  &:hover,
  &:active {
    color: #091e42;
    text-decoration: none;
  }

  &:focus {
    outline: none;
    border-color: #2684ff;
    box-shadow: none;
  }

  /* flexbox */
  display: flex;
  height: 100%;

  .result-item {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr;
    color: #7a7a7a;
    font-size: 0.8rem;
    grid-column-gap: 5px;
    .money {
      text-align: end;
      color: #079e42;
    }
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.8rem;
    }
  }
}
