@import "../../../../../../styles/variables";

#sample-result-select {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.5rem;
  span {
    font-weight: bold;
  }
  .select select {
    border-radius: 15px !important;
    &.fail {
      border-color: $red;
    }
    &.pass {
      border-color: $cyan;
    }
  }
}
