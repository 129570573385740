.equipment-edit-columns {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 0.8rem;
}

.equipment-edit-columns .field .file .file-name {
  max-width: 24em;
}

.equipment-edit-columns .field .image img {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 667px) {
  .equipment-edit-columns .field .file .file-name {
    max-width: 48em;
  } 
}

